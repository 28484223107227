import portfolioImg01 from "../images/Ui/01.jpg";
import portfolioImg02 from "../images/Ui/02.jpg";
import portfolioImg03 from "../images/Ui/03.jpg";
import portfolioImg04 from "../images/Ui/04.jpg";
import portfolioImg05 from "../images/Ui/05.jpg";
import portfolioImg06 from "../images/Ui/06.jpg";
import portfolioImg07 from "../images/Ui/07.jpg";
import portfolioImg08 from "../images/Ui/08.jpg"
import portfolioImg09 from "../images/Ui/09.jpg"
import portfolioImg10 from "../images/Ui/10.jpg";
import portfolioImg11 from "../images/Ui/11.jpg";
import portfolioImg12 from "../images/Logo/01.jpg"
import portfolioImg13 from "../images/Logo/02.jpg";
import portfolioImg14 from "../images/Logo/03.jpg";
import portfolioImg15 from "../images/Logo/04.jpg";
import portfolioImg16 from "../images/Logo/05.jpg";
import portfolioImg17 from "../images/Fast Fod/01.jpg";
import portfolioImg18 from "../images/Fast Fod/02.jpg"
import portfolioImg19 from "../images/Fast Fod/03.jpg"
import portfolioImg20 from "../images/Fast Fod/04.jpg"

const portfolios = [
  {
    id: "01",
    imgUrl: portfolioImg01,
  },
  {
    id: "02",
    imgUrl: portfolioImg02,
  },
  {
    id: "03",
    imgUrl: portfolioImg03,
  },
  {
    id: "04",
    imgUrl: portfolioImg04,
  },
  {
    id: "05",
    imgUrl: portfolioImg05,
  },
  {
    id: "06",
    imgUrl: portfolioImg06,
  },
    {
    id: "07",
    imgUrl: portfolioImg07,
  },
  {
    id: "08",
    imgUrl: portfolioImg08,
  },
  {
    id: "09",
    imgUrl: portfolioImg09,
  },
  {
    id: "10",
    imgUrl: portfolioImg10,
  },
  {
    id: "11",
    imgUrl: portfolioImg11,
  },
  {
    id: "12",
    imgUrl: portfolioImg12,
  },
  {
    id: "13",
    imgUrl: portfolioImg13,
  },
  {
    id: "14",
    imgUrl: portfolioImg14,
  },
  {
    id: "15",
    imgUrl: portfolioImg15,
  },
  {
    id: "16",
    imgUrl: portfolioImg16,
  },
  {
    id: "17",
    imgUrl: portfolioImg17,
  },
  {
    id: "18",
    imgUrl: portfolioImg18,
  },
  {
    id: "19",
    imgUrl: portfolioImg19,
  },
  {
    id: "20",
    imgUrl: portfolioImg20,
  },

]

export default portfolios;
