import React from 'react'
import { NavLink } from 'react-router-dom'

const Error = () => {
  return (
    <section className='container bgc min-h-screen w-full'>
        <h1  className='text-headingColor font-[900]
                    text-[1.8rem] sm:text-[40px] leading-[35px] sm:leading-[46px] w-fit h-fit overflow-hidden block ml-auto
                    mr-auto mt-40'>404 - Page Not Found</h1>
        <h3 
          className='text-headingColor font-[400]
          text-[1.2rem] sm:text-[40px] leading-[35px] sm:leading-[46px] w-fit h-fit overflow-hidden block ml-auto
          mr-auto mt-9'
        >Go Back to &nbsp;
            <NavLink to="/" className='text-primaryColor italic'>
            <span className='text-headingColor font-[600]'>&lt;</span> <span className='hover:underline'>Home Page</span> <span className='text-headingColor font-[600]'>&gt;</span>
            </NavLink>
        </h3>
    </section>
  )
}

export default Error
