import React from "react";
import { CiMail } from "react-icons/ci";
import { NavLink } from "react-router-dom";
import logo from '../../assets/images/Dex 01.svg'





const Footer = () => {
    const year = new Date().getFullYear()

    return(<footer className="bg-[#12141e] pt-12 bottom-0 ">
        <div className="container bg-inherit overflow-hidden">
            <div className="sm:flex items-center justify-between  bg-inherit">
                <div className="w-full sm:w-1/2 bg-inherit">
                    <h2 className="text-[26px] leading-10 text-white font-[600] mb-5
                    md:text-[2rem] bg-inherit">Want top-notch services <span className="text-primaryColor bg-inherit">?</span></h2>
                    <NavLink to="/contact" className='bg-primaryColor text-white font-[500] flex
                        items-center gap-2 hover:bg-smallTextColor ease-in duration-200 py-2 px-4 rounded-[8px] 
                         w-fit'>
                            <CiMail className=' bg-inherit'/> Contact
                        </NavLink>
                </div>
                <div className="w-full sm:w-1/2 bg-inherit">
                    <p className="bg-inherit text-gray-300 leading-7 mt-4 sm:mt-0 text-right">
                    A New Perspective of Creative Art
                    </p>
                    <div className="flex items-center justify-end gap-4 flex-wrap md:gap-8 mt-10 bg-inherit">
                        <span className="text-gray-300 font-[600] text-[15px] bg-inherit">
                            Follow Me:
                        </span>
                        {/* <span className="w-[35px] h-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer
                        text-center">
                            <a target="_blank" rel="noreferrer" href="https://twitter.com/mawais8086" className="text-gray-300 font-[500] text-[18px] bg-inherit">
                            <i class="ri-twitter-line bg-inherit"></i>
                            </a>
                        </span> */}

                        <span className="w-[35px] h-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer
                        text-center">
                            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=61554726157155&mibextid=ZbWKwL"  className="text-gray-300 font-[500] text-[18px] bg-inherit">
                            <i class="ri-facebook-line bg-inherit"></i>
                            </a>
                        </span>

                        <span className="w-[35px] h-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer
                        text-center">
                            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/dexartpkofficial?igsh=NWZsODB3ajV1ZzQ3"  className="text-gray-300 font-[500] text-[18px] bg-inherit">
                            <i class="ri-instagram-line bg-inherit"></i>
                            </a>
                        </span>

                        <span className="w-[35px] h-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer
                        text-center">
                            <a target="_blank" rel="noreferrer" href="https://wa.me/03097600443" className="text-gray-300 font-[500] text-[18px] bg-inherit">
                            <i class="ri-whatsapp-line bg-inherit"></i>
                            </a>
                        </span>

                    </div>
                </div>
            </div>

            <div className="bg-inherit">
                    <ul className="flex items-center gap-10 mt-10 justify-center bg-inherit">
                        <li className="bg-inherit"><NavLink className="text-gray-400 font-[600] bg-inherit transition duration-200 hover:text-primaryColor navi  " to='/'>About</NavLink></li>
                        <li className="bg-inherit"><NavLink className="text-gray-400 font-[600] bg-inherit transition duration-200 hover:text-primaryColor navi "  to='/services'>Services</NavLink></li>
                        <li className="bg-inherit"><NavLink className="text-gray-400 font-[600] bg-inherit transition duration-200 hover:text-primaryColor navi " to='/portfolio'>Portfolio</NavLink></li>
                        <li className="bg-inherit"><NavLink className="text-gray-400 font-[600] bg-inherit transition duration-200 hover:text-primaryColor navi " to='/contact'>Contact</NavLink></li>
                    </ul>
            </div>
        </div>
        <div className="bg-[#1b1329] py-5 mt-14">
            <div className="container bg-inherit"> 
                <div className="flex items-center justify-center sm:justify-between bg-inherit">
                    <div className="hidden sm:block bg-inherit">
                    <a href="/" className="cursor-pointer">
                        <img src={logo} className="sm:w-[100px] w-[75px]" alt="" />
                        </a>
                    </div>
                <div className="bg-inherit">
                    <p className="text-gray-400 text-[14px] bg-inherit">Copyright &copy; {year} by Dexart - All rights reserved</p>
                </div>

                </div>
            </div>
        </div>   
    </footer>);
};

export default Footer;