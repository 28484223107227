import React, {useRef, useEffect} from "react";
import {RiSendPlaneLine} from 'react-icons/ri';
import {CgMenuMotion} from 'react-icons/cg';
import { NavLink } from "react-router-dom";

import logo from '../../assets/images/Dex 02.svg'


const Header = () => {
    const headerRef = useRef(null)
    const menuRef = useRef(null)

    const stickyHeaderFunc = ()=>{
            window.addEventListener('scroll', ()=>{
                if(document.body.scrollTop > 80 || document.documentElement.scrollTop > 80){
                    headerRef.current.classList.add('sticky_header')
                }
                else{
                    headerRef.current.classList.remove('sticky_header')
                }
            })
        }
    
        useEffect(()=>{
            stickyHeaderFunc()
            return window.removeEventListener('scroll', stickyHeaderFunc)
        },[])


const toggleMenu = () => menuRef.current.classList.toggle('show_menu')

    return (<header
         ref={headerRef} className="w-full h-[80px] leading-[80px] flex items-center bgc">
        <div className="container">
            <div className="flex items-center justify-between">
                <a href="/" className="cursor-pointer">
                    <img src={logo} className="sm:w-[100px] w-[75px]"  alt="" />
                </a>
                <div className="menu" ref={menuRef} onClick={toggleMenu}>
                    <ul className="flex items-center gap-10">
                        <li><NavLink className="text-smallTextColor navi  font-[600] transition duration-200  hover:text-primaryColor" to="/">About</NavLink></li>
                        <li><NavLink className="text-smallTextColor navi font-[600] transition duration-200  hover:text-primaryColor"  to="/services">Services</NavLink></li>
                        <li><NavLink className="text-smallTextColor navi font-[600] transition duration-200  hover:text-primaryColor" to="/portfolio" >Portfolio</NavLink></li>
                        <li><NavLink className="text-smallTextColor navi font-[600] transition duration-200  hover:text-primaryColor" to="/contact">Contact</NavLink></li>
                    </ul>
                </div>
                <div className="flex items-center gap-4">
                    <NavLink to="/contact" className="w-fit">
                        <RiSendPlaneLine className="w-[35px] h-[35px] fill-primaryColor hover:fill-hover hover:scale-90 ease-out duration-150"/>
                         
                    </NavLink >

                <span onClick={toggleMenu} className="md:hidden cursor-pointer">
                    <CgMenuMotion className="w-[35px] h-[35px] text-primaryColor"/>
                </span>
                </div>   
            </div>
        </div>
    </header>
    )
};

export default Header;