import React, { useState } from 'react';
import data from '../../assets/data/portfolioData';

// ... (imports and other code)

const Portfolio = () => {
    const [selectedImage, setSelectedImage] = useState(null);
  
    const openImageModal = (imgUrl) => {
      setSelectedImage(imgUrl);
    };
  
    const closeImageModal = () => {
      setSelectedImage(null);
    };
  
    return ( 
      <section id='portfolio' className='bgc mt-10'>
        <div className="container min-h-screen">
          <div className="flex items-center justify-between flex-wrap ">
            <div
              data-aos='fade-left'
              data-aos-duration='1500'
              data-aos-delay='300' 
              className="mb-7 sm:mb-0"
            >
              <h3 className='text-headingColor text-[2.5rem] font-[700]'>
                Our Featured Projects
              </h3>
            </div>
          </div>
          <div className="flex items-center gap-4 flex-wrap mt-12">
            {data.map((portfolio, index) => (
              <div
                key={index}
                data-aos='fade-right'
                data-aos-duration='1700'
                data-aos-delay='400'
                className="group max-w-full sm:w-[48.5%] md:w-[31.8%] lg:w-[32.2%] relative z-[1]"
              >
                <figure>
                  <img
                    className='rounded-[8px] cursor-pointer'
                    src={portfolio.imgUrl}
                    alt=""
                    onClick={() => openImageModal(portfolio.imgUrl)}
                    onContextMenu={(e) => e.preventDefault()} 
                  />
                </figure>
              </div>
            ))}
          </div>
  
          {/* Modal for displaying the enlarged image */}
          {selectedImage && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-75 z-50" onClick={closeImageModal}>
              <div className="max-w-full max-h-full overflow-auto">
                <img
                onContextMenu={(e) => e.preventDefault()} 
                  className="rounded-[8px] cursor-pointer"
                  src={selectedImage}
                  alt=""
                  onClick={closeImageModal}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    );
  };
  
  export default Portfolio;
  
