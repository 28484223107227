import React from 'react'
import HeroImg from '../../assets/images/main.png';
import video from '../../assets/video/1222 (1).mp4';
import CountUp from 'react-countup';
import { MdWavingHand } from 'react-icons/md'
import { CiMail } from 'react-icons/ci';

import { NavLink } from "react-router-dom";


const Hero = () => {

    return (
        <section className='pt-0 bgc' id='about'>
            <div className='container pt-14 min-h-screen'>
                <div className='md:flex items-center justify-between sm:flex flex-col
            md:flex-row'>
                    <div className="w-full md:basis-1/2 ">
                        <h5 data-aos='fade-right' data-aos-duration='1500' className='text-headingColor
                    font-[600] text-[16px] flex gap-0'>
                            Hi
                            <span className='mt-1 ml-1 text-hover'> <MdWavingHand /></span>
                        </h5>
                        <h1 data-aos='fade-up' data-aos-duration='1500'
                            className='text-headingColor font-[800]
                    text-[1.6rem] sm:text-[40px] leading-[35px] sm:leading-[46px] mt-5 w-fit h-fit overflow-hidden'>
                            Welcome to DEXART
                        </h1>
                        <div
                            data-aos='fade-up'
                            data-aos-duration='1800'
                            data-aos-delay='200'
                            className='flex items-center gap-6 mt-7'
                        >
                            <NavLink to="/contact" className='bg-primaryColor text-white font-[500] flex
                        items-center gap-2 hover:bg-hover ease-in duration-200 py-2 px-4 rounded-[8px] 
                        '>
                                <CiMail className=' bg-inherit' /> Contact
                            </NavLink>
                            <NavLink to="/portfolio"
                                className='text-smallTextColor font-[600] text-[16px] border-b border-solid 
                        border-hover hover:border-b-4 ease-out duration-200'>
                                Our Potfolio
                            </NavLink>
                        </div>
                        <p
                            data-aos='fade-left'
                            data-aos-duration='1500'
                            className='flex gap-2 text-headingColor mt-12 font-[500] 
                        text-[15px] leading-7 sm:pl-14 sm:pr-10 text-justify'>

                            Where Imagination Takes Flight: A New and Unprecedented Perspective in the Expansive World of Creative Art, Guided 
                            by Innovation, Boundless Vision, and a Passion for Redefining the Boundaries of Artistic Expression.
                        </p>
                        <div className='flex items-center gap-9 mt-14'>
                            <span className='text-smallTextColor text-[15px] font-[600]' >Keep in touch</span>
                            {/* <span>
                                <a target='_blank'
                                    href="https://twitter.com/mawais8086" rel="noreferrer"
                                    className='text-smallTextColor text-[18px] font-[600]'>
                                    <i class="ri-twitter-fill"></i>
                                </a>
                            </span> */}

                            <span>
                                <a target='_blank'
                                    href="https://www.facebook.com/profile.php?id=61554726157155&mibextid=ZbWKwL" rel="noreferrer"
                                    className='text-smallTextColor text-[18px] font-[600]'>
                                    <i class="ri-facebook-fill"></i>
                                </a>
                            </span>

                            <span>
                                <a target='_blank'
                                    href="https://www.instagram.com/dexartpkofficial?igsh=NWZsODB3ajV1ZzQ3" rel="noreferrer"
                                    className='text-smallTextColor text-[18px] font-[600]'>
                                    <i class="ri-instagram-fill"></i>
                                </a>
                            </span>

                            <span>
                                <a target='_blank'
                                    href="https://wa.me/03097600443" rel="noreferrer"
                                    className='text-smallTextColor text-[18px] font-[600]'>
                                    <i class="ri-whatsapp-fill"></i>
                                </a>
                            </span>

                        </div>
                    </div>
                    <div className='basis-1/3 mt-10 sm:mt-0 '>
                        <figure className='flex items-center justify-center'>
                            <img className='' src={HeroImg} alt="" />
                        </figure>

                    </div>
                    <div className='md:basis-1/5 flex justify-between text-center mt-10 flex-wrap gap-3 md:gap-3
                md:mt-0 md:flex-col md:justify-end md:text-end'>
                        <div className="mb-10">
                            <h2 className='text-headingColor font-[700] text-[32px]'>
                                <CountUp start={0} end={5} duration={5} suffix='+' />
                            </h2>
                            <h4 className='text-headingColor font-[600] text-[18px]'>Years Of Experience</h4>
                        </div>

                        <div className="mb-10">
                            <h2 className='text-headingColor font-[700] text-[32px]'>
                                <CountUp start={0} end={100} duration={5} suffix='%' />
                            </h2>
                            <h4 className='text-headingColor font-[600] text-[18px]'>Success Rate</h4>
                        </div>

                        <div className="mb-10">
                            <h2 className='text-headingColor font-[700] text-[32px]'>
                                <CountUp start={0} end={75} duration={5} suffix='+' />
                            </h2>
                            <h4 className='text-headingColor font-[600] text-[18px]'>Satisfied Clients</h4>
                        </div>

                        <div className="mb-10">
                            <h2 className='text-headingColor font-[700] text-[32px]'>
                                <CountUp start={0} end={200} duration={5} suffix='+' />
                            </h2>
                            <h4 className='text-headingColor font-[600] text-[18px]'>Successful Projects</h4>
                        </div>
                    </div>
                </div>


<div className="mt-10 sm:mt-4">
    <video onContextMenu={(e) => e.preventDefault()}  autoPlay controls  src={video}></video>
</div>

                {/* <div className='flex-col mr-auto ml-auto'>
                    <div className='mt-36'>
                        <h2
                            data-aos='fade-left'
                            data-aos-duration='2000'
                            data-aos-delay='300'
                            className='text-primaryColor font-[800] text-[2.4rem] mb-5 
             text-center'><span className='text-headingColor'>&lt;</span>
                            My Skills
                            <span className='text-headingColor'>&gt;</span></h2>
                    </div>
                    <br />

                </div>
                <div
                    data-aos='fade-right'
                    data-aos-duration='2200'
                    data-aos-delay='400'
                    className=''>
                    <div className='flex-col columns-2  w-[70%] m-auto  mt-7 '>
                        {
                            Slider?.map((slider) => (
                                <img className='w-[120px] block m-auto h-[120px]  lg:w-[200px] lg:h-[200px] object-cover mb-7' src={slider.imgUrl} alt="/" />

                            ))
                        }
                    </div>
                </div> */}
            </div>
        </section>
    )
}

export default Hero
