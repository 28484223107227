import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';


const Contact = () => {

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_0b4jehp', 'template_xwt6q49', form.current, 'fw2j8nLdKeG_7MERd')
      .then((result) => {
        console.log(result.text);
        form.current.reset();
        toast.success('Message Sent Successfully!');
      }, (error) => {
        console.log(error.text);
        toast.error(error.text);
      });
  };
  return (
    <section id='contact' className='pb-16 bgc mt-10'>
      <div
        className='container min-h-screen'>
        <h2
          data-aos='fade-up'
          data-aos-duration='1200'
          data-aos-delay='200'
          className='text-headingColor font-[700] text-[2.5rem] mb-8'>Let's Collab</h2>
        <div className="md:flex justify-between items-center">
          <div
            data-aos='fade-right'
            data-aos-duration='1500'
            data-aos-delay='300'
            className="w-full md:w-1/2 h-[300px] sm:h-[450px]">
            <iframe title='google-maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d870837.2551783888!2d73.74588535864304!3d31.504708301555862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391c0976be00c015%3A0xb335638a3dc6295!2sBismillah%20Graphics!5e0!3m2!1sen!2s!4v1703267332635!5m2!1sen!2s"
              className='border-0 w-full h-full ' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div
            data-aos='fade-left'
            data-aos-duration='1500'
            data-aos-delay='300'
            className="w-full mt-8 md:mt-0 md:w-1/2 sm:h-[450px] lg:flex items-center  bg-backcolor shadow-[0_25px_40px_-15px_rgba(2,48,71)]
            px-4 lg:px-8 py-8">
            <form className='w-full bg-inherit' ref={form} onSubmit={sendEmail}>
              <input type="text" placeholder='Enter Your Name' className='mb-5 w-full p-3 focus:outline-none
                        rounded-[5px] ' name='from_name' required />
              <input type="Email" placeholder='Enter Your Email' className='mb-5 w-full p-3 focus:outline-none
                        rounded-[5px] ' name='user_email'
                pattern='[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$' required />
              <textarea type="text" rows={3} placeholder='Write Your Message' className='mb-5 w-full p-3 focus:outline-none
                        rounded-[5px]' name='message' required />
              <button className='w-full p-3 focus:outline-none rounded-[5px]
                    bg-smallTextColor text-white hover:bg-hover text-center ease-linear
                    duration-150'>Send</button>
            </form>
          </div>
        </div>
      </div>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </section >
  )
}


export default Contact
