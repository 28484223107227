import React from 'react';
import BeatLoader from "react-spinners/BeatLoader"


const Loader = () => {
    return (
        <div className="flex justify-center items-center h-screen">
           
           <BeatLoader color="#FB8500" />
        </div>

    );
};

export default Loader;
