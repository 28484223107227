import React from 'react';
import { Tilt } from 'react-tilt';
import { FaDrawPolygon} from "react-icons/fa";
import { FaRegCirclePlay } from "react-icons/fa6";
import { SiMaterialdesign } from "react-icons/si";






const Services = () => {
  return (
    <section id="services" className='bgc'>
        <div className="container lg:pt-5 min-h-screen">
            <div className='text-center'>
                <h2 className='text-headingColor font-[800] text-[2.4rem] mb-5'>What We bring to the table<span className='text-primaryColor'>?</span></h2>
                {/* <p className='lg:max-w-[600px] lg:mx-auto text-headingColor font-[500] text-[16px] leading-7'>I craft custom web solutions that help businesses establish their online 
                    presence, increase brand awareness, and engage with their target audience effectively.</p> */}
            </div>

            <div className='flex flex-col justify-center sm:py-12'>
                <div className='w-full py-3 px-2 sm:max-w-xl sm:mx-auto sm:px-0'>
                    <div className="relative text-gray-700 text-sm font-semibold">
                        {/* _______LINE IN MIDDLE_______ */}
                        <div className="hidden absolute w-1 sm:block bg-hover h-full left-1/2 
                        transform -translate-x-1/2"></div>
                        <div className="mt-6 sm:mt-0 sm:mb-12 ">
                            <div className="flex items-center flex-col sm:flex-row ">
                                <div className="flex justify-start w-full mx-auto items-center ">
                                    <div className="w-full sm:w-1/2 sm:pr-8">
                                        <Tilt options={{max:45, scale: 1, speed:350}} data-aos="fade-right" data-aos-duration="1200" className="bg-backcolor p-4 rounded shadow-lg hover:shadow-2xl group hover:bg-primaryColor
                                         ease-in duration-200 hover:rounded-3xl">
                                            <h3 className='text-primaryColor font-[700] mb-3 group-hover:text-white
                                             text-xl group-hover:bg-inherit pt-2 md:pt-0 group-hover:underline'>
                                               GRAPHIC SOLUTIONS
                                            </h3>

                                            <p className='text-[15px] text-smallTextColor group-hover:text-white
                                             group-hover:bg-inherit leading-7 '>
                                                We are dedicated to creating visually stunning and functional designs. Our expert team understands the importance of incorporating brand identity, messaging, and audience needs. </p>
                                        </Tilt>
                                    </div>
                                </div>
                                <div className="rounded-full bg-primaryColor border-hover border-4 w-10 h-10
                                absolute left-1/2 transform -translate-x-1/2 -translate-y-1/4 sm:translate-y-0 
                                flex items-center justify-center overflow-hidden">
                                    <figure className='bg-primaryColor'>
                                    {/* <i class="ri-code-box-line text-white text-lg  bg-inherit"></i> */}
                                    <FaDrawPolygon className=' text-white text-lg  bg-inherit' />

                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 sm:mt-0 sm:mb-12 ">
                            <div className="flex items-center flex-col sm:flex-row ">
                                <div className="flex justify-end w-full mx-auto items-center ">
                                    <div className="w-full sm:w-1/2 sm:pl-8">
                                        <Tilt options={{max:45, scale: 1, speed:350}} data-aos="fade-left" data-aos-duration="1300" data-aos-delay="50" className="bg-backcolor p-4 rounded shadow-lg hover:shadow-2xl group hover:bg-primaryColor
                                         ease-in duration-200 hover:rounded-3xl">
                                            <h3 className='text-primaryColor font-[700] mb-3 group-hover:text-white
                                             text-xl group-hover:bg-inherit pt-2 md:pt-0 group-hover:underline'>
                                                VIDEO MAKING AND EDITING

                                            </h3>

                                            <p className='text-[15px] text-smallTextColor group-hover:text-white
                                             group-hover:bg-inherit leading-7'>
                                                Offering unparalleled Video Making and Editing services, we bring your creative visions to life. From high-quality footage to stunning visual effects, we excel in providing innovative video production solutions.</p>
                                        </Tilt>
                                    </div>
                                </div>
                                <div className="rounded-full bg-primaryColor border-hover border-4 w-10 h-10
                                absolute left-1/2 transform -translate-x-1/2 -translate-y-1/4 sm:translate-y-0 
                                flex items-center justify-center overflow-hidden">
                                    <figure className='bg-primaryColor'>
                                    {/* <i class="ri-server-fill text-white text-lg  bg-inherit"></i> */}
                                    <FaRegCirclePlay className='text-white text-lg  bg-inherit'/>

                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 sm:mt-0 sm:mb-12 ">
                            <div className="flex items-center flex-col sm:flex-row ">
                                <div className="flex justify-start w-full mx-auto items-center ">
                                    <div className="w-full sm:w-1/2 sm:pr-8">
                                        <Tilt options={{max:45, scale: 1, speed:350}} data-aos="fade-right" data-aos-duration="1400" data-aos-delay="100" className="bg-backcolor p-4 rounded shadow-lg hover:shadow-2xl group hover:bg-primaryColor
                                         ease-in duration-200 hover:rounded-3xl">
                                            <h3 className='text-primaryColor font-[700] mb-3 group-hover:text-white
                                             text-xl group-hover:bg-inherit pt-2 md:pt-0 group-hover:underline'>
                                                UI DESIGNING
                                            </h3>

                                            <p className='text-[15px] text-smallTextColor group-hover:text-white
                                             group-hover:bg-inherit leading-7 '>
                                                Our skilled designers work diligently to create visually stunning interfaces that seamlessly integrate with various technological platforms, ensuring optimal functionality and accessibility.</p>
                                        </Tilt>
                                    </div>
                                </div>
                                <div className="rounded-full bg-primaryColor border-hover border-4 w-10 h-10
                                absolute left-1/2 transform -translate-x-1/2 -translate-y-1/4 sm:translate-y-0 
                                flex items-center justify-center overflow-hidden">
                                    <figure className='bg-primaryColor'>
                                    {/* <i class="ri-layout-2-line text-white text-lg  bg-inherit"></i> */}
                                    <SiMaterialdesign  className='text-white text-lg  bg-inherit' />

                                    </figure>
                                </div>
                            </div>
                        </div>
                        {/* <div className="mt-6 sm:mt-0 sm:mb-12 ">
                            <div className="flex items-center flex-col sm:flex-row ">
                                <div className="flex justify-end w-full mx-auto items-center ">
                                    <div className="w-full sm:w-1/2 sm:pl-8">
                                        <Tilt options={{max:45, scale: 1, speed:350}} data-aos="fade-left" data-aos-duration="1500" data-aos-delay="150" className="bg-backcolor p-4 rounded shadow group hover:bg-primaryColor
                                         ease-in duration-200 hover:rounded-3xl">
                                            <h3 className='text-primaryColor font-[700] mb-3 group-hover:text-white
                                             text-xl group-hover:bg-inherit pt-2 md:pt-0 group-hover:underline'>
                                                SEO
                                            </h3>

                                            <p className='text-[15px] text-smallTextColor group-hover:text-white
                                             group-hover:bg-inherit leading-7'>
                                                Have SEO specialist, proficient in optimizing websites for search engines
                                                 and increasing their online visibility and traffic.</p>
                                        </Tilt>
                                    </div>
                                </div>
                                <div className="rounded-full bg-primaryColor border-hover border-4 w-10 h-10
                                absolute left-1/2 transform -translate-x-1/2 -translate-y-1/4 sm:translate-y-0 
                                flex items-center justify-center overflow-hidden">
                                    <figure className='bg-primaryColor'>
                                    <i class="ri-seo-line text-white text-lg  bg-inherit"></i>
                                    </figure>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Services
