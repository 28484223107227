import { useEffect, useState } from 'react';
import Aos from 'aos';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Hero from './components/UI/Hero';
import Services from './components/UI/Services';
import Portfolio from './components/UI/Portfolio';
import Contact from './components/UI/Contact';
import Error from './components/UI/Error'; // Replace './Loader' with the actual path to your Loader component
import Loader from './components/Loader/Loader';
import Cursor from './components/side components/Cursor';

// import AnimatedCursor from "react-animated-cursor"

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Aos.init();

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <Router>
      <div>
      <Cursor/>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Header />
            <Routes>
              <Route path="/" element={<Hero />} />
              <Route path="/services" element={<Services />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<Error />} />
            </Routes>
            <Footer />
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
